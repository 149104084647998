import { BehaviorSubject, Observable } from 'rxjs'

import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private lastBreadCrumb: BehaviorSubject<string> = new BehaviorSubject<string>('')
  lastBreadCrumb$: Observable<string> = this.lastBreadCrumb.asObservable()

  setLastBreadCrumb(value: string): void {
    this.lastBreadCrumb.next(value)
  }
}
